<template>
    <div class="w-100 pl-4 mb-4">
        <v-card outlined>
            <v-card-text>
                <p class="display-1 text--primary" v-html="$t('houses.heading')"></p>
                <p v-html="$tc('houses.subheading', houses.length, {memberid: getActingMemberid })"></p>
            </v-card-text>
            <v-row>
                <v-col class="pl-6 pr-6">
                    <search
                        :search-value="search"
                        adjutable
                        ref="searchComponent"
                        @emit-switch-type="emitSwitchType"
                        @input="search = $event"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-data-table
                        :headers="headers"
                        :items="houses"
                        :search="search"
                        :custom-filter="customFilter"
                        :loading="$store.getters.getLoaders.houses"
                        :loading-text="$t('common.datatable.loading')"
                        :no-data-text="$t('common.datatable.noData')"
                        :no-results-text="$t('common.datatable.noResults')"
                        :items-per-page="25"
                        :footer-props="{
                          'items-per-page-options': [25, 50, 100, -1],
                          'items-per-page-text': $t('common.datatable.rowsPerPage'),
                          'items-per-page-all-text': $t('common.words.all')
                      }"
                        class="elevation-2 ma-3 cursor-pointer"
                        @click:row="clickRow"
                    >
                        <template v-slot:item.haus_imghash="{ item }">
                            <v-avatar
                                v-if="item.haus_imghash"
                                size="35"
                            >
                                <v-img
                                    :src="getImage(item)"
                                    :lazy-src="getImage(item)"
                                    :alt="'Main image of ' + item.haus_titel"
                                />
                            </v-avatar>
                            <v-avatar v-else size="35">
                                <v-icon :color="$store.getters.getColors.secondary1">
                                    mdi-image-area
                                </v-icon>
                            </v-avatar>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import Search from '@/components/Allgemein/Suche.vue'
import { mapGetters } from 'vuex'
import HousesService from '@/services/houses.service'

export default {
    name: 'Houses',
    components: { Search },
    metaInfo: {
        title: 'Houses',
    },
    data() {
        return {
            customFilterEnabled: (this.$cookies.get('searchType') === 'true'),
            houses: [],
            expanded: [],
            singleExpand: false,
            search: '',
        }
    },
    sockets: {
        async houseUpdate(data) {
            if (data.memberid === this.$store.getters.getActingMemberid) {
                await this.getHouses()
                await this.bannerMessageForSocketEvent('houses', data.memberid)
            }
        },
    },
    computed: {
        ...mapGetters([
            'getColors',
            'getActingMemberid',
        ]),
        headers: function () {
            return [
                {
                    text: '',
                    value: 'haus_imghash',
                    sortable: false,
                    align: 'center',
                    divider: true,
                    width: '10%',
                },
                {
                    text: this.$i18n.t('common.labels.id'),
                    value: 'haus_id',
                    align: 'end',
                    divider: true,
                    width: '30%',
                },
                {
                    text: this.$i18n.t('common.labels.title'),
                    value: 'haus_titel',
                    divider: true,
                    width: '30%',
                },
                {
                    text: this.$i18n.t('common.labels.location'),
                    value: 'haus_ort',
                    divider: true,
                    width: '30%',
                },
            ]
        },
    },
    methods: {
        async bannerMessageForSocketEvent(dataType, memberid) {
            await this.$store.dispatch('socketEventBanner', {
                message: `Some ${ dataType } changed for member ${ memberid }!`,
                autoHide: true,
            })
        },
        emitSwitchType(value) {
            this.customFilterEnabled = value
        },
        customFilter(value, search, item) {

            let test = false

            if (!this.customFilterEnabled) {
                //Info: check only numeric search values
                if (/[0-9]/.test(search))
                    test = item.hasOwnProperty('haus_id') && item.haus_id.toString().includes(search.toString())

                //Info: check only alpha numeric search values
                if (/([a-zA-z])/.test(search))
                    test = item.hasOwnProperty('haus_titel') && item.haus_titel.toString().toLowerCase().includes(search.toString().toLowerCase())
            }
            else
                test = (item.hasOwnProperty('haus_id') && item.haus_id.toString().includes(search.toString())) || (item.hasOwnProperty('haus_titel') && item.haus_titel.toString().toLowerCase().includes(search.toString().toLowerCase()))

            return test
        },
        clickRow(item) {
            this.$router.push({ path: `/house/${ item.haus_id }` })
        },
        getImage(house) {
            if (house.haus_imghash) {
                const md5 = house.haus_imghash
                return `https://api.feondi.de/common/image.php?u=xsigns&p=sfgashfasdfajshbfkas&art=haus&memberid=${ this.getActingMemberid }&entityid=${ house.haus_id }&md5=${ md5 }`
            }

            return ''
        },
        async getHouses() {
            await this.$store.dispatch('setLoaders', { houses: true })
            this.houses = await HousesService.getHousesByMember({ memberid: this.$store.getters.getActingMemberid })
            await this.$store.dispatch('setLoaders', { houses: false })
        },
    },
    async created() {
        await this.getHouses()
    },
}
</script>
